import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/PLITHUS-logo파일 7.png";
import {
  faAngleDown,
  faAngleUp,
  faBuilding,
  faBullhorn,
  faBullseye,
  faCoins,
  faCheckToSlot,
  faFileUpload,
  faShieldAlt,
  faUserAlt,
  faShop,
  faShoppingBag,
  faEnvelope,
  faPaperPlane,
  faGift,
  faListAlt,
  faTicketSimple,
  faUser,
  faHistory,
  faNewspaper,
  faStarOfDavid,
  faStar,
  faHandPaper,
  faDownload,
  faRankingStar,
  faPeopleArrows,
  faTextSlash,
  faAward,
  faEnvelopeOpenText,
  faCalendar,
  faGamepad,
  faWheatAwnCircleExclamation,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import LogoutModal from "./Modal/LogoutModal";
import styled from "styled-components";

const SideBarStyle = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #5c5c5c;
  position: sticky;
`;

const SideBarLogo = styled.div`
  font-size: 30px;
  font-weight: bold;
  padding: 30px 10px 10px 30px;
`;

const SideBarMenu = styled.div`
  margin-bottom: 2rem;
  color: #555;
  padding-bottom: 30px;
  min-height: 100vh;
`;

const SideBarHead = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const SideBarItems = styled.div`
  font-size: 20px;
  color: #c3c2c2;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin: 40px 0 0 30px;
  padding-bottom: 5px;
  cursor: pointer;
`;

const SideBarItem = styled.div`
  font-size: 17px;
  color: white;
  background-color: #444444;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px 7px 7px 37px;
  width: 100%;
  &:hover {
    background-color: white;
    color: #333742;
  }
`;

const SideBarIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  padding-right: 10px;
`;

const SideLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: center;
`;

const SideBarArrows = styled.div`
  font-size: 18px;
  color: #c3c2c2;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin: 40px 40px 0 15px;
  cursor: pointer;
`;

const LogoutBg = styled.div`
  background-color: black;
  bottom: 0;
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LogoutTxt = styled.div`
  color: white;
  padding: 10px;
`;

export default function Sidebar() {
  const [modal, setModal] = useState(false);

  function onClose() {
    setModal(false);
  }

  const [category, setCategory] = useState({
    dashboard: false,
    notice: false,
    gallery: false,
    gameNews: false,
    gameTest: false,
    inAppgames: false,
    survey: false,
    user: false,
    point: false,
    community: false,
    gifticonShop: false,
    push: false,
    coupon: false,
    supporter: false,
    quest: false,
    retension: false,
  });

  const {
    dashboard,
    notice,
    gallery,
    gameTest,
    gameNews,
    inAppgames,
    retension,
    survey,
    user,
    point,
    community,
    gifticonShop,
    push,
    coupon,
    quest,
    supporter,
  } = category;

  function onClick(e: any) {
    const clickedId = e.currentTarget.id as keyof typeof category;
    const now = category[clickedId];
    setCategory({
      ...category,
      [clickedId]: !now,
    });
  }

  return (
    <>
      {modal && <LogoutModal onClose={onClose} />}
      <SideBarStyle>
        <SideBarMenu>
          <Link to="/">
            <SideBarLogo>
              <img src={logo} alt="" width="130px" />
            </SideBarLogo>
          </Link>
          <SideBarHead onClick={onClick} id="dashboard">
            <SideBarItems onClick={onClick}>대시보드</SideBarItems>
            {dashboard ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {dashboard && (
            <>
              <SideLink to="/">
                <SideBarItem>
                  <SideBarIcon icon={faBuilding} />
                  대시보드
                </SideBarItem>
              </SideLink>
              <SideLink to="/userStats">
                <SideBarItem>
                  <SideBarIcon icon={faUser} />
                  유저 지표
                </SideBarItem>
              </SideLink>{" "}
              <SideLink to="/appStats">
                <SideBarItem>
                  <SideBarIcon icon={faHistory} />앱 버전 기록
                </SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="notice">
            <SideBarItems onClick={onClick}>공지사항</SideBarItems>
            {notice ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {notice && (
            <>
              {" "}
              <SideLink to="/announcements">
                <SideBarItem>
                  <SideBarIcon icon={faTextSlash} />
                  공지글
                </SideBarItem>
              </SideLink>
              <SideLink to="/notices">
                <SideBarItem>
                  <SideBarIcon icon={faBullseye} />
                  팝업창
                </SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="gallery">
            <SideBarItems onClick={onClick}>온라인 전시관</SideBarItems>
            {gallery ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {gallery && (
            <>
              <SideLink to="/gallery/test">
                <SideBarItem>테스트 관리</SideBarItem>
              </SideLink>
              <SideLink to="/gallery/banner">
                <SideBarItem>배너 관리</SideBarItem>
              </SideLink>
              <SideLink to="/gallery/survey">
                <SideBarItem>게임 설문 관리</SideBarItem>
              </SideLink>
              <SideLink to="/gallery/orders">
                <SideBarItem>상점 구매 내역</SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="gameNews">
            <SideBarItems onClick={onClick}>게임뉴스 관리</SideBarItems>
            {gameNews ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}{" "}
          </SideBarHead>
          {gameNews && (
            <>
              {" "}
              <SideLink to="/gameNews">
                <SideBarItem>
                  <SideBarIcon icon={faNewspaper} />
                  게임뉴스
                </SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="gameTest">
            <SideBarItems onClick={onClick}>테스트 관리</SideBarItems>
            {gameTest ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {gameTest && (
            <>
              <SideLink to="/tests">
                <SideBarItem>
                  <SideBarIcon icon={faFileUpload} />
                  게임테스트 등록
                </SideBarItem>
              </SideLink>
              <SideLink to="/test/phaseOne">
                <SideBarItem>
                  <SideBarIcon icon={faShieldAlt} />
                  게임테스트 인증
                </SideBarItem>
              </SideLink>
              <SideLink to="/testerActivity/cheering">
                <SideBarItem>
                  <SideBarIcon icon={faPeopleArrows} />
                  테스터 인증
                </SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="inAppgames">
            <SideBarItems onClick={onClick}>인앱게임 관리</SideBarItems>
            {inAppgames ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}{" "}
          </SideBarHead>
          {inAppgames && (
            <>
              <SideLink to="/inAppgames">
                <SideBarItem>
                  <SideBarIcon icon={faGamepad} />
                  인앱게임
                </SideBarItem>
              </SideLink>
            </>
          )}

          <SideBarHead onClick={onClick} id="retension">
            <SideBarItems onClick={onClick}>리텐션 콘텐츠</SideBarItems>
            {retension ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {retension && (
            <>
              <SideLink to="/retension/daymissions">
                <SideBarItem>
                  <SideBarIcon icon={faGamepad} />
                  데이미션 관리
                </SideBarItem>
              </SideLink>
              <SideLink to="/retension/bounties">
                <SideBarItem>
                  <SideBarIcon icon={faGamepad} />
                  현상금 관리
                </SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="survey">
            <SideBarItems onClick={onClick}>설문 관리</SideBarItems>
            {survey ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {survey && (
            <>
              <SideLink to="/surveys">
                <SideBarItem>
                  <SideBarIcon icon={faHandPaper} />
                  게임설문
                </SideBarItem>
              </SideLink>
              <SideLink to="/surveys/bounceRate">
                <SideBarItem>
                  <SideBarIcon icon={faHandPaper} />
                  이탈률 게임설문
                </SideBarItem>
              </SideLink>
              {/* <SideLink to="/donwload">
                <SideBarItem>
                  <SideBarIcon icon={faDownload} />
                  설문 다운로드
                </SideBarItem>
              </SideLink> */}
            </>
          )}
          <SideBarHead onClick={onClick} id="user">
            <SideBarItems onClick={onClick}>유저 관리</SideBarItems>
            {user ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {user && (
            <>
              <SideLink to="/users">
                <SideBarItem>
                  <SideBarIcon icon={faUserAlt} />
                  유저 목록
                </SideBarItem>
              </SideLink>
              <SideLink to="/attendance">
                <SideBarItem>
                  <SideBarIcon icon={faCalendar} />
                  출석 목록
                </SideBarItem>
              </SideLink>
            </>
          )}
          {/** */}
          <SideBarHead onClick={onClick} id="supporter">
            <SideBarItems onClick={onClick}>서포터즈 관리</SideBarItems>
            {supporter ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {supporter && (
            <>
              <SideLink to="/supporters">
                <SideBarItem>
                  <SideBarIcon icon={faGift} />
                  서포터즈 목록
                </SideBarItem>
              </SideLink>
            </>
          )}

          {/** */}
          <SideBarHead onClick={onClick} id="point">
            <SideBarItems onClick={onClick}>포인트 관리</SideBarItems>
            {point ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {point && (
            <>
              <SideLink to="/rewards">
                <SideBarItem>
                  <SideBarIcon icon={faCoins} />
                  포인트 적립
                </SideBarItem>
              </SideLink>
              <SideLink to="/plistars">
                <SideBarItem>
                  <SideBarIcon icon={faStar} />
                  플리스타 적립
                </SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="community">
            <SideBarItems onClick={onClick}>커뮤니티 관리</SideBarItems>
            {community ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {community && (
            <>
              <SideLink to="/ads">
                <SideBarItem>
                  <SideBarIcon icon={faBullhorn} />
                  배너 관리
                </SideBarItem>
              </SideLink>
              <SideLink to="/elections">
                <SideBarItem>
                  <SideBarIcon icon={faCheckToSlot} />
                  투표 관리
                </SideBarItem>
              </SideLink>
              <SideLink to="/reviewAwards">
                <SideBarItem>
                  <SideBarIcon icon={faAward} />
                  리뷰 어워드 관리
                </SideBarItem>
              </SideLink>
              <SideLink to="/events">
                <SideBarItem>
                  <SideBarIcon icon={faEnvelopeOpenText} />
                  이벤트 관리
                </SideBarItem>
              </SideLink>
            </>
          )}

          <SideBarHead onClick={onClick} id="quest">
            <SideBarItems onClick={onClick}>퀘스트 관리</SideBarItems>
            {quest ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}{" "}
          </SideBarHead>
          {quest && (
            <>
              {" "}
              <SideLink to="/quests">
                <SideBarItem>
                  <SideBarIcon icon={faQuestionCircle} />
                  퀘스트
                </SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="gifticonShop">
            <SideBarItems onClick={onClick}>포인트샵 관리</SideBarItems>
            {gifticonShop ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {gifticonShop && (
            <>
              <SideLink to="/gifticons">
                <SideBarItem>
                  <SideBarIcon icon={faShop} />
                  기프티콘 상품
                </SideBarItem>
              </SideLink>
              <SideLink to="/orders">
                <SideBarItem>
                  <SideBarIcon icon={faShoppingBag} />
                  기프티콘 구매 내역
                </SideBarItem>
              </SideLink>
              <SideLink to="/plistarOrders">
                <SideBarItem>
                  <SideBarIcon icon={faRankingStar} />
                  플리스타 구매 내역
                </SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="push">
            <SideBarItems onClick={onClick}>푸시알림 내역</SideBarItems>
            {push ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {push && (
            <>
              <SideLink to="/notifications">
                <SideBarItem>
                  <SideBarIcon icon={faEnvelope} />
                  알림 내역
                </SideBarItem>
              </SideLink>
              <SideLink to="/pushes">
                <SideBarItem>
                  <SideBarIcon icon={faPaperPlane} />
                  푸시알림 전송
                </SideBarItem>
              </SideLink>
            </>
          )}
          <SideBarHead onClick={onClick} id="coupon">
            <SideBarItems onClick={onClick}>쿠폰 관리</SideBarItems>
            {coupon ? (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleUp} onClick={onClick} />
              </SideBarArrows>
            ) : (
              <SideBarArrows>
                <FontAwesomeIcon icon={faAngleDown} onClick={onClick} />
              </SideBarArrows>
            )}
          </SideBarHead>
          {coupon && (
            <>
              <SideLink to="/coupons">
                <SideBarItem>
                  <SideBarIcon icon={faListAlt} />
                  쿠폰 목록
                </SideBarItem>
              </SideLink>
              <SideLink to="/usages">
                <SideBarItem>
                  <SideBarIcon icon={faTicketSimple} />
                  쿠폰 사용 내역
                </SideBarItem>
              </SideLink>
            </>
          )}
        </SideBarMenu>

        <LogoutBg>
          <LogoutTxt onClick={() => setModal(true)}>로그아웃</LogoutTxt>
        </LogoutBg>
      </SideBarStyle>
    </>
  );
}
