import { useQuery } from "react-query";
import styled from "styled-components";
import { Link } from "react-router-dom";
import GallerySurveyListAPI from "./Data/GallerySurveyListAPI";
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;
const SelectBoxContainer = styled.div`
  position: relative;
`;

const SelectBox = styled.select`
  width: 152px;
  height: 43px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px #00000040;
  border: none;
  font-weight: 600;
  padding: 0px 20px;
  color: #555555;
  appearance: none;
  outline: none;
`;

const Button = styled.div`
  background-color: #2282e9;
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
`;

const Table = styled.div`
  width: 100%;
  text-align: center;
`;

const THead = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 2fr;
  background-color: #2282e9;
  color: #fff;
  height: 54px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const HeaderTh = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
`;

const TBodyTr = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 2fr;
  border: 2px solid #f1f1f1;
  height: 64px;
  margin-bottom: 8px;
  border-radius: 5px;
`;

const Td = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15;
`;

const DetailButton = styled.button`
  width: 126px;
  height: 43px;
  background-color: #f0f9ff;
  border-radius: 10px;
  font-weight: 400;
  cursor: pointer;
  border: none;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #2282e9;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #2282e9;
      }
    }
  }
`;

export default function GallerySurveyList() {
  const pageNum = sessionStorage.getItem("galleryOrderPage") || "1";

  const [category, setCategory] = useState<string>("MULTIPLE_CHOICE");

  const { data: surveys, refetch } = useQuery(
    ["surveys", pageNum],
    async () => {
      return await GallerySurveyListAPI(
        "GALLERY",
        Number(pageNum),
        10,
        category
      );
    },
    {
      onSuccess: (res) => {
        console.log(res);
      },
    }
  );
  const handlePageChange = () => {
    refetch();
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Multiple") {
      setCategory("MULTIPLE_CHOICE");
    } else if (e.target.value === "Subjective") {
      setCategory("SUBJECTIVE");
    }
  };

  useEffect(() => {
    refetch();
  }, [category]);

  return (
    <Wrapper>
      <HeaderContainer>
        <TitleContainer>
          <Title>게임 설문 관리</Title>
          <SelectBoxContainer>
            <SelectBox onChange={handleSelectChange}>
              <option value={"Multiple"}>객관식</option>
              <option value={"Subjective"}>주관식</option>
            </SelectBox>
            <FontAwesomeIcon
              icon={faPlay}
              style={{
                rotate: "90deg",
                color: "#555555",
                position: "absolute",
                top: "14px",
                right: "17px",
              }}
            />
          </SelectBoxContainer>
        </TitleContainer>
        <Link to="add" style={{ textDecoration: "none" }}>
          <Button>설문 추가</Button>
        </Link>
      </HeaderContainer>
      <Table>
        <THead>
          <HeaderTh>No.</HeaderTh>
          <HeaderTh>질문내용</HeaderTh>
          <HeaderTh>카테고리</HeaderTh>
          <HeaderTh>공개여부</HeaderTh>
          <HeaderTh />
        </THead>
        {surveys?.data?.page?.content?.map((survey: any, index: number) => {
          return (
            <TBodyTr key={survey.id}>
              <Td>{survey.id}</Td>
              <Td>{survey.content}</Td>
              <Td>
                {survey.category === "MULTIPLE_CHOICE"
                  ? "객관식 질문"
                  : survey.category === "SUBJECTIVE"
                  ? "주관식 질문"
                  : survey.category === "PHOTO_AUTHENTICATION"
                  ? "사진 인증 질문"
                  : "코드 오류"}
              </Td>
              <Td>{survey.visible === true ? "보임" : "숨김"}</Td>
              <Td>
                <Link
                  to={"/gallery/survey/" + survey.id}
                  className="link"
                  state={{
                    id: survey.id,
                  }}
                >
                  <DetailButton>자세히</DetailButton>
                </Link>
              </Td>
            </TBodyTr>
          );
        })}
      </Table>
      <PaginationWrapper>
        <Pagination
          activePage={Number(pageNum)}
          itemsCountPerPage={10}
          totalItemsCount={surveys?.data?.page?.pageable.totalElements || 1}
          pageRangeDisplayed={5}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={handlePageChange}
        />
      </PaginationWrapper>
    </Wrapper>
  );
}
