import instance from "API/MainAPI";

export default function GalleryOrderPageAPI(page: number) {
  return instance.get("/v3/admins/orders/managements", {
    params: {
      eventHost: "GALLERY",
      pageSize: 10,
      pageNumber: page - 1,
    },
  });
}
