import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import GallerySurveyCreationAPI from "./Data/GallerySurveyCreationAPI";
import Modal from "./Modal/Modal";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 22px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Button = styled.div`
  background-color: #2282e9;
  width: 158px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
`;

const Content = styled.div``;

const SubTitle = styled.span`
  font-weight: 600;
  margin-bottom: 22px;
`;

const Comment = styled.span`
  font-weight: 500;
  color: #575757;
`;

const SurveyContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1147fr 405fr;
  gap: 20px;
  margin-bottom: 40px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LargeInput = styled.input`
  border: 2px solid #2282e9;
  border-radius: 6px;
  height: 60px;
  padding: 0px 20px;
`;

const SelectBoxContainer = styled.div`
  position: relative;
`;

const SelectBox = styled.select`
  width: 152px;
  height: 43px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px #00000040;
  border: none;
  font-weight: 600;
  padding: 0px 20px;
  color: #555555;
  appearance: none;
  outline: none;
`;

const RadioBtn = styled.input``;

const AddQuestion = styled.div`
  border: 2px solid #2282e9;
  height: 61px;
  border-radius: 30px;
  color: #2282e9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-top: 22px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const SmallInput = styled.input`
  height: 43px;
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0px 20px;
`;

const DeleteBtn = styled.div`
  background-color: #c9e8fe;
  width: 154px;
  height: 43px;
  color: #575757;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default function GallerySurveyAdd() {
  const { register, control, getValues, setValue, watch } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "choices",
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [modal, setModal] = useState(false);

  function closeAdd() {
    setModal(false);
  }

  const formData = new FormData();

  const watchCategory = watch("category");

  async function CallAPI() {
    const contentValue = getValues("content");
    const visibleValue = getValues("visible");
    const allowedChoicesValue =
      watchCategory !== "MULTIPLE_CHOICE" ? 0 : getValues("allowedChoices");
    const minAnswerLengthValue =
      watchCategory !== "SUBJECTIVE" ? 0 : getValues("minAnswerLength");
    const categoryValue = getValues("category");
    const choicesValue = getValues("choices");
    const inputArray: { content: string; visible: boolean }[] = choicesValue;

    for (let i = 0; i < inputArray.length; i++) {
      formData.append("choices[" + i + "].content", inputArray[i].content);
      // @ts-ignore
      formData.append("choices[" + i + "].visible", inputArray[i].visible);
    }

    formData.append("content", contentValue);
    formData.append("visible", visibleValue);
    formData.append("allowedChoices", allowedChoicesValue);
    formData.append("minAnswerLength", minAnswerLengthValue);
    formData.append("category", categoryValue);
    formData.append("surveyCategory", "GALLERY");

    await adsAddMutation.mutateAsync(formData);
  }

  const adsAddMutation = useMutation(GallerySurveyCreationAPI, {
    onSuccess: () => {
      setModal(false);

      alert("설문 등록이 완료되었습니다.");
      queryClient.invalidateQueries("gallerySurveys");
      navigate(-1);
    },
    onError: () => {
      setModal(false);
    },
  });

  function onAdd() {
    const contentValue = getValues("content");
    const visibleValue = getValues("visible");
    const categoryValue = getValues("category");
    const choicesValue = getValues("choices");
    const inputArr: { content: string; visible: boolean }[] = choicesValue;
    const allowedChoicesValue =
      watchCategory === "MULTIPLE_CHOICE" ? getValues("allowedChoices") : 0;
    const allFieldsFilled =
      contentValue && visibleValue && categoryValue && choicesValue;
    if (
      (categoryValue == "SUBJECTIVE" ||
        categoryValue == "PHOTO_AUTHENTICATION") &&
      inputArr.length != 0
    ) {
      alert(
        "카테고리가 주관식이나 사진 첨부일때는 선택지를 등록하시면 안됩니다."
      );
    } else if (allFieldsFilled) {
      setModal(true);
    } else {
      alert("내용, 숨김여부, 선택지 허용 갯수, 분류, 선택지 입력해주세요.");
    }
  }

  useEffect(() => {
    setValue("category", "MULTIPLE_CHOICE");
  }, []);

  return (
    <Wrapper>
      {modal && <Modal close={closeAdd} CallAPI={CallAPI} />}
      <HeaderContainer>
        <Title>게임 설문 등록</Title>
        <Button onClick={onAdd}>등록하기</Button>
      </HeaderContainer>
      <Content>
        {watchCategory === "MULTIPLE_CHOICE" && (
          <SurveyContentContainer>
            <InputContainer>
              <SubTitle>설문내용</SubTitle>
              <LargeInput type="text" {...register("content")} />
            </InputContainer>
            <InputContainer>
              <TitleContainer>
                <SubTitle style={{ marginBottom: "0px" }}>
                  다중 투표 허용 갯수
                </SubTitle>
                <Comment>*숫자만 입력 가능합니다.</Comment>
              </TitleContainer>
              <LargeInput
                type="number"
                min="1"
                {...register("allowedChoices")}
              />
            </InputContainer>
          </SurveyContentContainer>
        )}
        {watchCategory === "SUBJECTIVE" && (
          <SurveyContentContainer>
            <InputContainer>
              <SubTitle>설문내용</SubTitle>
              <LargeInput type="text" {...register("content")} />
            </InputContainer>
            <div />
          </SurveyContentContainer>
        )}
        <div style={{ display: "flex", gap: "120px", marginBottom: "40px" }}>
          <InputContainer>
            <SubTitle>카테고리</SubTitle>
            <SelectBoxContainer>
              <SelectBox {...register("category")}>
                <option value="MULTIPLE_CHOICE">객관식</option>
                <option value="SUBJECTIVE">주관식</option>
              </SelectBox>
              <FontAwesomeIcon
                icon={faPlay}
                style={{
                  rotate: "90deg",
                  color: "#555555",
                  position: "absolute",
                  top: "14px",
                  right: "17px",
                }}
              />
            </SelectBoxContainer>
          </InputContainer>
          <InputContainer>
            <SubTitle>설문 숨김 여부</SubTitle>
            <div style={{ display: "flex", gap: "96px" }}>
              <div>
                <RadioBtn type="radio" value="true" {...register("visible")} />
                <label>보이기</label>
              </div>
              <div>
                <RadioBtn type="radio" value="false" {...register("visible")} />
                <label>숨기기</label>
              </div>
            </div>
          </InputContainer>
        </div>
        {watchCategory === "MULTIPLE_CHOICE" && (
          <>
            <SubTitle>설문 선택지 등록</SubTitle>
            <AddQuestion
              onClick={() => {
                append({
                  visible: false,
                });
              }}
            >
              + 질문 등록하기
            </AddQuestion>
            {fields.map((item, index) => {
              return (
                <div
                  key={item.id}
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <span
                    style={{
                      color: "#555555",
                      fontWeight: "600",
                    }}
                  >
                    선택지 #{`${index + 1}`}
                  </span>
                  <SmallInput
                    style={{ width: "60%" }}
                    {...register(`choices.${index}.content`, {
                      required: true,
                    })}
                  />
                  <div>
                    <RadioBtn
                      type="radio"
                      value="true"
                      {...register(`choices.${index}.visible`)}
                    />
                    <label>보이기</label>
                  </div>
                  <div>
                    <RadioBtn
                      type="radio"
                      value="false"
                      {...register(`choices.${index}.visible`)}
                    />
                    <label>숨기기</label>
                  </div>
                  <DeleteBtn onClick={() => remove(index)}>Delete</DeleteBtn>
                </div>
              );
            })}
          </>
        )}
        {watchCategory === "SUBJECTIVE" && (
          <>
            <div style={{ display: "flex", gap: "32px" }}>
              <SubTitle>최소 답변 길이</SubTitle>
              <Comment>*숫자만 입력 가능합니다.</Comment>
            </div>
            <SurveyContentContainer>
              <SmallInput
                type="number"
                min="0"
                {...register("minAnswerLength")}
              />
              <div />
            </SurveyContentContainer>
          </>
        )}
      </Content>
    </Wrapper>
  );
}
