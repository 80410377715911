import styled from "styled-components";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import GalleryTestListAPI from "./data/GalleryTestListApi";
import formatDate from "components/common/function/formatDate";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 40px;
  width: 100%;
`;

const Title = styled.div`
  color: #2282e9;
  font-weight: 700;
  font-size: 28px;
  margin-top: 50px;
  margin-bottom: 54px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  width: 100%;
`;

const Content = styled(Link)`
  width: 100%;
  height: 326px;
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 231px;
`;

const Info = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
`;

export default function GalleryTestList() {
  const { data: galleryList } = useQuery(
    ["galleryList"],
    async () => await GalleryTestListAPI()
  );
  return (
    <Wrapper>
      <Title>테스트 인증 관리</Title>
      <Container>
        {galleryList?.data?.page?.content?.map((gallery: any) => (
          <Content key={gallery.id} to={`${gallery.id}/phaseOne`}>
            <Image src={gallery.bannerUrl}></Image>
            <Info>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#2282E9",
                  marginBottom: "10px",
                }}
              >
                {gallery.title}
              </span>
              <span
                style={{
                  fontWeight: "500",
                  color: "#8E8E8E",
                }}
              >
                {formatDate(gallery.startedAt)} -{" "}
                {formatDate(gallery.startedAt)}
              </span>
            </Info>
          </Content>
        ))}
      </Container>
    </Wrapper>
  );
}
