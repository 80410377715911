import { useQuery } from "react-query";
import styled from "styled-components";
import GalleryOrderPageAPI from "./Data/GalleryOrderPageAPI";
import { Link } from "react-router-dom";
import formatDate from "components/common/function/formatDate";
import Pagination from "react-js-pagination";
import { useEffect } from "react";
import { clearGalleryOrder } from "components/common/clearStorage";

const Wrapper = styled.div`
  padding: 50px 40px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #2282e9;
`;

const Table = styled.div`
  width: 100%;
  text-align: center;
`;

const THead = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  background-color: #333742;
  color: #fff;
  height: 54px;
  margin-bottom: 20px;
  border-radius: 4px;
`;

const HeaderTh = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
`;

const TBodyTr = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  border: 2px solid #f1f1f1;
  height: 64px;
  margin-bottom: 8px;
  border-radius: 5px;
`;

const Td = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15;
`;

const DetailButton = styled.button`
  width: 126px;
  height: 43px;
  background-color: #f0f9ff;
  border-radius: 10px;
  font-weight: 400;
  cursor: pointer;
  border: none;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 30px;
      height: 30px;
      border: 1px solid #e2e2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      font-size: 1rem;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &.active {
        background-color: #2282e9;
        a {
          color: white;
        }
      }

      a {
        &:hover,
        &.active {
          color: #333742;
        }
        text-decoration: none;
        color: #2282e9;
      }
    }
  }
`;

export default function GalleryOrderList() {
  const pageNum = sessionStorage.getItem("galleryOrderPage") || "1";

  const { data: orderData, refetch } = useQuery(
    ["galleryOrder", pageNum],
    () => GalleryOrderPageAPI(Number(pageNum)),
    {
      keepPreviousData: true,
    }
  );

  const handlePageChange = (pageNumber: number) => {
    sessionStorage.setItem("galleryOrderPage", pageNumber.toString());
    refetch();
  };

  useEffect(() => {
    clearGalleryOrder();
  }, []);

  let count = 0;
  return (
    <Wrapper>
      <HeaderContainer>
        <Title>상점 구매 내역</Title>
      </HeaderContainer>
      <Table>
        <THead>
          <HeaderTh>No.</HeaderTh>
          <HeaderTh>닉네임</HeaderTh>
          <HeaderTh>회사명</HeaderTh>
          <HeaderTh>제품명</HeaderTh>
          <HeaderTh>분류</HeaderTh>
          <HeaderTh>보석</HeaderTh>
          <HeaderTh>주문일시</HeaderTh>
          <HeaderTh>발송일시</HeaderTh>
          <HeaderTh />
        </THead>
        {orderData?.data.page.content &&
          orderData?.data.page.content.map((order: any, index: number) => {
            count = count + 1;
            return (
              <TBodyTr key={order.id}>
                <Td>
                  {orderData?.data?.page?.pageable.totalElements -
                    orderData?.data?.page?.pageable.pageNumber *
                      orderData?.data?.page?.pageable.pageSize -
                    count +
                    1}
                </Td>
                <Td>{order.consumer.nickname}</Td>
                <Td>{order.product.company}</Td>
                <Td>{order.product.name}</Td>
                <Td>
                  {order.product.cashierCategory === "REWARD_EXCHANGE"
                    ? "재화 교환 방식"
                    : order.product.cashierCategory === "KAKAO_BIZ"
                    ? "카카오 발송"
                    : order.product.cashierCategory === "CODE"
                    ? "쿠폰코드 발송"
                    : order.product.cashierCategory === "MANUAL"
                    ? "관리자 직접 발송"
                    : "코드오류"}
                </Td>
                <Td>{order.product.price}</Td>
                <Td> {formatDate(order.orderedAt)}</Td>
                <Td>
                  {order.exchanged ? formatDate(order.exchangedAt) : "미발송"}
                </Td>
                <Td>
                  <Link
                    to={"/gallery/orders/" + order.id}
                    className="link"
                    state={{ id: order.id }}
                  >
                    <DetailButton>자세히</DetailButton>
                  </Link>
                </Td>
              </TBodyTr>
            );
          })}
      </Table>
      <PaginationWrapper>
        <Pagination
          activePage={Number(pageNum)}
          itemsCountPerPage={10}
          totalItemsCount={orderData?.data?.page?.pageable.totalElements || 1}
          pageRangeDisplayed={5}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={handlePageChange}
        />
      </PaginationWrapper>
    </Wrapper>
  );
}
